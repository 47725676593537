import React from "react";
import "../../assets/fonts/loader.css";
import "../../css/main.css";
import { HtmlHead } from "./HtmlHead";
import { Navigation } from "./Navigation";
import { Footer } from "./Footer";
import CookieConsent from "react-cookie-consent";
import { useStaticQuery, graphql, Link } from "gatsby";
import ReactGA from "react-ga";

type Props = {
  children: React.ReactNode;
};

function Layout({ children }: Props): React.ReactElement {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            trackingId
          }
        }
      }
    `
  );
  return (
    <div className="container mx-auto">
      <HtmlHead />

      <Navigation />
      <main className="flex-grow">{children}</main>
      <Footer />
      <CookieConsent
        location="bottom"
        buttonText="Akzeptieren"
        declineButtonText="Nicht Akzeptieren"
        cookieName="gatsby-gdpr-google-analytics"
        onAccept={() => {
          ReactGA.initialize(data.site.siteMetadata.trackingId);
        }}
      >
        Um Ihnen ein angenehmes Online-Erlebnis zu ermöglichen, setzen wir auf
        unserer Webseite Cookies ein. Durch das Weitersurfen auf
        schmiedekunst-ohlsen.com erklären Sie sich mit der Verwendung von
        Cookies einverstanden. Detaillierte Informationen und wie Sie der
        Verwendung von Cookies jederzeit widersprechen können, finden Sie in
        unseren{" "}
        <Link
          to="/privacy-policy"
          className="text-gray-300 hover:text-white underline"
        >
          Datenschutzhinweisen
        </Link>
        .
      </CookieConsent>
    </div>
  );
}

export default Layout;
